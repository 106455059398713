<template>
  <v-autocomplete
    v-bind="$attrs"
    v-on="$listeners"
    v-model="selected"
    :items="items"
    :loading="loading"
    clearable
    item-color="grey darken-4"
    item-value="id"
    item-text="date"
    :label="$t('time.overtime_request.workplan')"
  >
    <template slot="selection" slot-scope="data">
      {{ data.item.date | formattedDate }}
    </template>
    <!-- <template slot="item" slot-scope="data">
             <v-list-item-avatar size="36" :color="data.item.leavePolicy.leaveType.color">
                 <v-icon size="18" dark color="white">{{data.item.leavePolicy.leaveType.icon }}</v-icon>
             </v-list-item-avatar>
             <v-list-item-content>
                 <v-list-item-title v-html="data.item.leavePolicy.leaveType.name"/>
                 <v-list-item-subtitle>{{$t('leave.request.used')}}: {{ data.item.used.toFixed(2)}} / {{$t('leave.request.remaining')}}: {{(data.item.previousBalance + data.item.leavePolicy.accrualCount - data.item.used).toFixed(2)}}</v-list-item-subtitle>
             </v-list-item-content>
         </template>-->
  </v-autocomplete>
</template>

<script>
  import { WORKPLAN } from "../query";

  export default {
    inheritAttrs: false,
    name: "workplan-picker",
    props: {
      value: String,
      date: String
    },
    data: () => ({
      selected: null,
      items: [],
      loading: false
    }),
    watch: {
      value: {
        handler(workplanDate) {
          this.selected = workplanDate;
        },
        immediate: true
      },
      date: {
        handler(date) {
          if (date) {
            this.fetchItems();
          } else {
            this.items = [];
          }
        },
        deep: true
      }
    },
    methods: {
      async fetchItems() {
        this.loading = true;
        await this.$apollo
          .query({
            query: WORKPLAN,
            variables: {
              criteria: {
                employees: [this.$store.state.auth.user.id],
                beginDate: this.date,
                endDate: this.date
              }
            }
          })
          .then(({ data, errors }) => {
            if (!data.error && !errors) {
              const requestStatus = ["APPROVED", "PENDING"];
              this.items = data.filterWorkplans.filter(item => {
                if (item.overtimes.some(row => !requestStatus.includes(row.requestStatus))) {
                  return item;
                } else if (item.overtimes.length === 0) {
                  return item;
                }
              });
            }
          })
          .catch(e => {
            this.$helpers.showNotification(e.message);
          })
          .finally(() => {
            this.loading = false;
          });
      },
      formatDate(item) {
        return this.$helpers.formattedDate(item.date);
      }
    }
  };
</script>

<style scoped></style>
